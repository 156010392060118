import { Injectable } from '@angular/core';
import { ApiServer } from '../models/Profile.model';
import { StateService } from '../state/state.service';

@Injectable({
  providedIn: 'root',
})
export class ServerService {
  constructor(private state: StateService) {}

  set(id: number, reload = true) {
    const servers = this.state.get<ApiServer[]>('servers');
    const server = servers.find((x) => x.id == id);

    this.state.set({ selectedServer: server });
    if (window && reload) {
      window.location.reload();
    }
  }
}
